.forecastGraph {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.forecast {
    height: 100%;
    width: 100%;
    display: inline-block;
}
