.text {
    font-size: 2vh;
}

.weather-elements {
    width: inherit;
    display: flex;
    float: right;
    margin: 0 auto;
    height: 100%;
}

.weatherElement {
    display: inline-block;
    margin: 0 auto;
    width: 18%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
}
