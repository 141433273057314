.header {
    color: whitesmoke;
    height: 100%;
}

.time-container {
    float: left;
    display: inline-block;
    width: 35%;
    height: 100%;
}

.weather-container {
    display: inline-block;
    width: 65%;
    height: 100%;
}

.time {
    height: 50%;
    font-weight: normal;
}

.date {
    height: 50%;
    font-weight: lighter;
}

@media only screen and (max-width: 600px) {
    .time-container, .weather-container, .weather-elements {
        text-align: center;
        display: inline-block;
        float: none;
        width: 100%;
    }

    .time-container {
        height: 50%;
    }

    .weather-container {
        margin-top: 7%;
        transform: translateY(10%);
        height: 40%;
    }

    .date, .time {
        width: 100%;
        font-size: 250%;
    }
}

