.dayContainer {
    margin-top: 5%;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.dayText {
    color: white;
    font-size: 2vh;
}

.dayText p {
    margin: 0;
}

.predictionDay {
    margin: 0 1%;
    display: inline-block;
    width: 12%;
}

.predictionDay img {
    width: 70%;
}

.dayTempMax {
    font-weight: normal;
}

.dayTempMin {
    font-weight: lighter;
}
