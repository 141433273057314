.header-container {
    width: 100%;
    height: 20%;
    display: inline-block;
}

.forecast-container {
    height: 80%;
    width: 100%;
    display: inline-block;
}
